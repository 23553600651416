<template>
    <section class="map-view">
        <div class="map-header">
            <div v-if="mapActiveCentres?.length > 1" class="home-page-content">
                <h2 class="component-title luxury">{{ headers.title }}</h2>
                <div class="tabs">
                    <div
                        v-for="(item, index) in mapActiveCentres"
                        :class="{ active: activeMapArea === index }"
                        @click="activeMapArea = index"
                        class="tab fira uppercase"
                        data-scale-type="1"
                    >
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <div v-else class="inner-container">
                <h2 class="component-title">{{ headers.title }}</h2>
                <span class="component-description fira">
                    {{ headers.teaser }}
                </span>
            </div>
        </div>
        <div id="map" class="map">
            <div
                v-for="(item, index) in mapActiveCentres"
                :key="item.id"
                :class="{ active: activeMapArea === index }"
                class="map-inner-item"
            >
                <transition name="map">
                    <GMapMap
                        ref="gmap"
                        v-if="activeMapArea === index"
                        :center="item.center"
                        :options="mapOptions"
                    >
                        <GMapCluster>
                            <GMapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :icon="m.icon"
                                :draggable="false"
                                @click="markerHover($event, m)"
                            >
                                <GMapInfoWindow
                                    :opened="activeMarker.id === m.id"
                                    :options="{
                                        pixelOffset: {
                                            width: 0,
                                            height: 150,
                                        },
                                    }"
                                >
                                    <div
                                        :class="{
                                            active: mapInfoWindowTransition,
                                        }"
                                        class="map-pop-up"
                                    >
                                        <div class="content">
                                            <img
                                                :src="activeMarker.hoverImage"
                                                alt=""
                                            />
                                            <h2 class="title luxury">
                                                {{ activeMarker.title }}
                                            </h2>
                                            <svg
                                                width="38"
                                                height="10"
                                                viewBox="0 0 38 10"
                                                fill="none"
                                                @click="
                                                    goToLink(
                                                        activeMarker.linkUrl
                                                    )
                                                "
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                                                    fill="#940128"
                                                ></path>
                                            </svg>
                                            <span class="description fira">{{
                                                    activeMarker.linkTitle
                                                }}</span>
                                        </div>
                                        <div
                                            @click="closeMarkerPopUp"
                                            class="close-container"
                                        >
                                            <img
                                                src="../../../assets/icons/close.png"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </GMapInfoWindow>
                            </GMapMarker>
                        </GMapCluster>
                    </GMapMap>
                </transition>
            </div>
        </div>
        <!--        <div @click="changeRoute" class="map-footer">-->
        <!--            <h2 class="title luxury">{{ $fn.tr('Location') }}</h2>-->
        <!--            <div class="get-touch">-->
        <!--                <svg-->
        <!--                    width="38"-->
        <!--                    height="10"-->
        <!--                    viewBox="0 0 38 10"-->
        <!--                    fill="none"-->
        <!--                    xmlns="http://www.w3.org/2000/svg"-->
        <!--                >-->
        <!--                    <path-->
        <!--                        fill-rule="evenodd"-->
        <!--                        clip-rule="evenodd"-->
        <!--                        d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166-->
        <!--                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415-->
        <!--                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746-->
        <!--                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015-->
        <!--                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961-->
        <!--                              0.65 5.64961H35.0287L31.8652 8.75479Z"-->
        <!--                        fill="#940128"-->
        <!--                    ></path>-->
        <!--                </svg>-->
        <!--                <span class="title fira uppercase">{{ headers.full_list_title }}</span>-->
        <!--            </div>-->
        <!--        </div>-->
    </section>
</template>

<script setup>
import {defineProps, computed, ref, onMounted, provide} from "vue";
import {useMenuStore} from "../../../store/pinia/menu";
import {useRouter} from "vue-router";
import {watch} from "vue";

const router = useRouter();

let mapOptions = ref({
    zoom: 15,
    minZoom: 15,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: [
        {
            elementType: "geometry",
            stylers: [
                {
                    color: "#1d2c4d",
                },
            ],
        },
        {
            elementType: "labels.text.fill",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            elementType: "labels.text.stroke",
            stylers: [
                {
                    color: "#1a3646",
                },
            ],
        },
        {
            featureType: "landscape",
            stylers: [
                {
                    color: "#ffffff",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
                {
                    color: "#283d6a",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#6f9ba5",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "labels.text.stroke",
            stylers: [
                {
                    color: "#1d2c4d",
                },
            ],
        },
        {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#7fb86c",
                },
            ],
        },
        {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#3C7680",
                },
            ],
        },
        {
            featureType: "road",
            stylers: [
                {
                    color: "#d1d1d1",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "geometry",
            stylers: [
                {
                    color: "#304a7d",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#d1d1d1",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#595f6e",
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
                {
                    color: "#2c6675",
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#d1d1d1",
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "labels.text.stroke",
            stylers: [
                {
                    color: "#d1d1d1",
                },
            ],
        },
        {
            featureType: "road.local",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#d1d1d1",
                },
            ],
        },
        {
            featureType: "road.local",
            elementType: "labels",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "transit",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#98a5be",
                },
            ],
        },
        {
            featureType: "transit",
            elementType: "labels.text.stroke",
            stylers: [
                {
                    color: "#ffffff",
                },
            ],
        },
        {
            featureType: "transit.line",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "transit.line",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#ffffff",
                },
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
                {
                    color: "#3a4762",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "geometry",
            stylers: [
                {
                    color: "#0e1626",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [
                {
                    color: "#4d8ec9",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#4e6d70",
                },
            ],
        },
    ],
});

const props = defineProps({
    contentData: {
        type: Object,
    },
    showAllArea: {
        type: Boolean,
        default: true,
    },
});
let menuStore = useMenuStore();
let marker = require("../../../assets/icons/marker.png");
const markers = computed(() =>
    props.contentData?.data?.list?.map((item) => {
        return {
            ...item,
            position: {
                lat: item?.map?.[0]?.lat,
                lng: item?.map?.[0]?.long,
            },
            hoverImage: item.Hover_Icon?.[0]?.devices?.desktop,
            icon: item.Icon?.[0]?.devices?.desktop,
            linkTitle: item.link?.title,
            linkUrl: item.link?.url,
        };
    })
);
let goToLink = (url) => {
    window.open(url, "_blank");
};
let headers = computed(() => props.contentData?.conf?.header || {});
let centres = computed(() =>
    menuStore.indx?.terms?.map_city_centers?.map((item) => ({
        ...item,
        center: {
            lat: Number(item.center_lat),
            lng: Number(item.center_long),
        },
    }))
);
const gmap = ref(null);
let mapInfoWindowTransition = ref(false);
let activeMarker = ref({});
let activeMapArea = ref(0);
const getDarkEvent = computed(() => menuStore.isDark);

let markersCentres = computed(() =>
    markers.value?.map((item) => item.map_city_centers?.[0])
);
let mapActiveCentres = computed(() =>
    centres.value?.filter((item) => markersCentres.value?.includes(item.id))
);

const markerHover = (e, m) => {
    activeMarker.value = m;
    setTimeout(() => {
        mapInfoWindowTransition.value = true;
    }, 100);
};
const closeMarkerPopUp = () => {
    activeMarker.value = {};
    mapInfoWindowTransition.value = false;
};
const changeRoute = () => {
    router.push({path: headers.value.full_list_url});
};
const zoomEventListener = () => {
    let map = document.querySelector("#map");
    map.addEventListener("wheel", (event) => {
        event.ctrlKey
            ? (menuStore.disableScrollStatus = true)
            : (menuStore.disableScrollStatus = false);
    });
};
const changeMapStyle = () => {
    if (getDarkEvent.value) {
        mapOptions.value.styles = [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#1d2c4d",
                    },
                ],
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#8ec3b9",
                    },
                ],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1a3646",
                    },
                ],
            },
            {
                featureType: "administrative.country",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#4b6878",
                    },
                ],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "administrative.land_parcel",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#64779e",
                    },
                ],
            },
            {
                featureType: "administrative.province",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#4b6878",
                    },
                ],
            },
            {
                featureType: "landscape",
                stylers: [
                    {
                        color: "#111929",
                    },
                ],
            },
            {
                featureType: "landscape.man_made",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#334e87",
                    },
                ],
            },
            {
                featureType: "landscape.natural",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#023e58",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#283d6a",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#111929",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#6f9ba5",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1d2c4d",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#023e58",
                    },
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#3C7680",
                    },
                ],
            },
            {
                featureType: "poi.place_of_worship",
                elementType: "geometry.fill",
                stylers: [
                    {
                        visibility: "on",
                    },
                ],
            },
            {
                featureType: "poi.place_of_worship",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#98a5be",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#2c6675",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#023e58",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        color: "#255763",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#b0d5ce",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#023e58",
                    },
                ],
            },
            {
                featureType: "road.local",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "transit",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "transit",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#98a5be",
                    },
                ],
            },
            {
                featureType: "transit",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1d2c4d",
                    },
                ],
            },
            {
                featureType: "transit.line",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#283d6a",
                    },
                ],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#3a4762",
                    },
                ],
            },
            {
                featureType: "water",
                stylers: [
                    {
                        color: "#111929",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#0e1626",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#111929",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#4e6d70",
                    },
                ],
            },
        ];
    } else {
        mapOptions.value.styles = [
            {
                elementType: "geometry",
                stylers: [
                    {
                        color: "#1d2c4d",
                    },
                ],
            },
            {
                elementType: "labels.text.fill",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1a3646",
                    },
                ],
            },
            {
                featureType: "landscape",
                stylers: [
                    {
                        color: "#ffffff",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#283d6a",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#6f9ba5",
                    },
                ],
            },
            {
                featureType: "poi",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#1d2c4d",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#7fb86c",
                    },
                ],
            },
            {
                featureType: "poi.park",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#3C7680",
                    },
                ],
            },
            {
                featureType: "road",
                stylers: [
                    {
                        color: "#d1d1d1",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#304a7d",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#d1d1d1",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#595f6e",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#2c6675",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#d1d1d1",
                    },
                ],
            },
            {
                featureType: "road.highway",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#d1d1d1",
                    },
                ],
            },
            {
                featureType: "road.local",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#d1d1d1",
                    },
                ],
            },
            {
                featureType: "road.local",
                elementType: "labels",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "transit",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#98a5be",
                    },
                ],
            },
            {
                featureType: "transit",
                elementType: "labels.text.stroke",
                stylers: [
                    {
                        color: "#ffffff",
                    },
                ],
            },
            {
                featureType: "transit.line",
                stylers: [
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "transit.line",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#ffffff",
                    },
                    {
                        visibility: "off",
                    },
                ],
            },
            {
                featureType: "transit.station",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#3a4762",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "geometry",
                stylers: [
                    {
                        color: "#0e1626",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "geometry.fill",
                stylers: [
                    {
                        color: "#4d8ec9",
                    },
                ],
            },
            {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [
                    {
                        color: "#4e6d70",
                    },
                ],
            },
        ];
    }
}
// WATCHER
watch(getDarkEvent, () => {
    changeMapStyle()
});
onMounted(() => {
    changeMapStyle()
    zoomEventListener();
});
</script>

<style lang="scss">
.map-view {
    position: relative;
    color: $primaryNavy;

    .map-header {
        width: 100%;
        will-change: transform;

        .component-title {
            transition: all 0.45s ease-out;
            color: $primaryNavy;

            @media only screen and (max-width: 1023px) {
                font-size: 34px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 28px;
            }
        }

        .component-description {
            font-weight: 400;
            font-size: 21px;
            margin-top: 13px;
            display: block;
            color: $primaryNavy;
        }

        .home-page-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .tabs {
                display: flex;
                align-items: center;
                background: $greyBg;
                border-radius: 30px;
                margin-top: 34px;
                margin-bottom: 55px;
                width: fit-content;

                .tab {
                    color: $primaryNavy;
                    font-weight: 700;
                    font-size: 13px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    padding: 13px 34px;
                    border-radius: 30px;
                    cursor: pointer;
                    transition: all 0.4s ease;

                    &.active {
                        background: $burgundy;
                        color: white;
                    }
                }
            }
        }

        .inner-container {
            padding: 35px 360px 55px;

            @include mq(desktop-sm) {
                padding: 35px 150px 55px;
            }
            @include mq(tablet) {
                padding: 35px 195px 34px;
            }
            @include mq(tablet-sm) {
                padding: 20px 20px 20px;
            }
            @include mq(mobile) {
                padding: 21px 21px 21px;
            }

            .component-title,
            .component-description {
            }
        }
    }

    .map {
        width: 100%;
        position: relative;
        height: 1000px;
        will-change: transform;
        @media only screen and (max-width: 1650px) and (min-width: 1024px) {
            height: 700px;
        }
        @media only screen and (max-width: 1023px) {
            height: 369px;
        }

        .map-inner-item {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;

            &.active {
                z-index: 1;
            }
        }

        .vue-map-container {
            width: 100%;
            height: 100%;

            .vue-map {
                width: 100%;
                height: 100%;
            }
        }
    }

    .map-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 90px 0;

        .title {
            font-weight: 400;
            font-size: 55px;
            text-transform: uppercase;
            @media only screen and (max-width: 1023px) {
                font-size: 34px;
            }
        }

        .get-touch {
            display: flex;
            align-items: center;
            margin-top: 19px;
            cursor: pointer;

            .title {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                margin-left: 13px;
                @media only screen and (max-width: 1023px) {
                    font-size: 13px;
                }
            }
        }
    }

    .map-pop-up {
        background: $primaryNavy;
        border: 2px solid $primaryNavy;
        padding: 28px 60px;
        color: white;
        opacity: 0;
        transform: translateY(30px);
        transition: all 0.5s ease-out;

        &.active {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
        }

        .close-container {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .title {
                font-weight: 400;
                font-size: 21px;
                text-align: center;
                text-transform: uppercase;
                margin-top: 21px;
            }

            svg {
                margin: 28px 0 17px;
            }

            .description {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                font-family: FiraGO;
            }
        }
    }

    .gm-style-iw-d {
        overflow: unset !important;
    }

    .gm-style-iw-t {
        &::after {
            display: none !important;
        }
    }

    .gm-style .gm-style-iw-c {
        padding: 0;
        background: transparent;
        box-shadow: unset;
        border-radius: unset;
        overflow: unset !important;

        button {
            display: none !important;
        }
    }

    .map-leave-active {
        transition: all 0.3s ease-out;
    }

    .map-enter-active {
        transition: all 0.5s 0.3s ease-out;
    }

    .map-enter-from,
    .map-leave-to {
        opacity: 0;
        transform: scale(0.9);
    }
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .map-view {
            color: white;

            .component-title,
            .component-description {
                color: white !important;
            }

            .inner-container {
                background: #111929;
            }
        }
    }
}
</style>
